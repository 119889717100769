var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, CloseIcon, Form, Radio, showErrorMessage, SignatureIcon, Spinner, SuccessSmallIcon, Tooltip, UnsuccessSmallIcon, } from "@g4p/react-styleguide-library";
import { Space, Typography, Upload } from "antd";
import { ParticipantRole } from "../../../flows/BankerCreateDokuBox/services/createDokuBoxApi";
import moment from "moment";
import { DokuStatus } from "../../admin/DokuBoxList/services/domain";
import { Container, DraggerWrapperFlex, FileExtension, FileName, FileNameContainer, FileNameWrapper, HalfContainer, Icon, InvalidSigningUsers, RadioButtonContainer, SigningClientUserRe, UploadStatusStyled, } from "../../banker/BankerManageDokuBox/styles";
import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../../services/appContext";
import { getLocaleFromLocalStorage, getLoggedInUserName } from "../../../services/appStorage";
import { useTenantContext } from "../../../services/tenantContext";
import { usePermission } from "../../../utils/permissions";
import { executeAxios } from "../../../services/axiosHook";
import { useFormatMessage } from "../../../services/formatMessageHook";
import { formatServerError, isDocumentSignedAPI, } from "../../banker/BankerManageDokuBox/services/bankerManageDokuBoxApi";
import { UserType } from "../DokuMessages/services/domain";
import ElectronicSignatureTenant from "../ElectronicSignature/ElectronicSignatureTenant";
import { dateFormat } from "../../../services/formatters";
import { ActionButtonWrapperStyled, Description, DokuAdditionalDataDiv, DokuPanelWrapper, StyledDownloadIcon, StyledPaperClipIcon, StyledRadio, UndoDeleteButton, } from "./styles";
import { DownloadIcon } from "@cbp/fe-commons";
import DokuActionAdditionalDataTenant from "./DokuActionAdditionalDataTenant";
import { getByLocale } from "../../../services/appApi";
import DokuPanelBodyProgressBar from "./DokuPanelBodyProgressBar";
var DokuPanelBody = function (props) {
    var _a, _b, _c;
    var formatMessage = useFormatMessage().formatMessage;
    var hasAnyOfTheRoles = usePermission().hasAnyOfTheRoles;
    var _d = useState(false), uploadTransitionAvailable = _d[0], setUploadTransitionAvailable = _d[1];
    var _e = useState(false), sendToArchiveTransitionAvailable = _e[0], setSendToArchiveTransitionAvailable = _e[1];
    var _f = useState(null), isDokuValidateSignatureDlgVisible = _f[0], setIsDokuValidateSignatureDlgVisible = _f[1];
    var _g = useState([]), unsavedActions = _g[0], setUnsavedActions = _g[1];
    var _h = useState(true), showUploadPlaceholder = _h[0], setShowUploadPlaceholder = _h[1];
    var _j = useState(false), showUploadSpinner = _j[0], setShowUploadSpinner = _j[1];
    var _k = useState(), uploadedFile = _k[0], setUploadedFile = _k[1];
    var _l = useState(false), isUploaded = _l[0], setUploaded = _l[1];
    var _m = useState([]), dokuTransitions = _m[0], setDokuTransitions = _m[1];
    var _o = useState(props.doku.dokuAction), currentDokuAction = _o[0], setCurrentDokuAction = _o[1];
    var _p = useState(!((_a = props.doku.dokuStaticActions) === null || _a === void 0 ? void 0 : _a.includes("ValidateSignature")) && !props.doku.bankSignedDokuUploadNeeded), sendForArchivingPossible = _p[0], setSendForArchivingPossible = _p[1];
    var _q = useState(!((_b = props.doku.dokuStaticActions) === null || _b === void 0 ? void 0 : _b.includes("ValidateSignature"))), sendToArchivePossible = _q[0], setSendToArchivePossible = _q[1];
    var _r = useState(!((_c = props.doku.dokuStaticActions) === null || _c === void 0 ? void 0 : _c.includes("ValidateSignature"))), archivePossible = _r[0], setArchivePossible = _r[1];
    var user = useAppContext().user;
    var tenant = useTenantContext().tenant;
    var sendToClientVisible = props.doku.status === DokuStatus.WAITING_BANK_SIGNATURE_AUX &&
        props.userType === UserType.BANKER &&
        props.dokuBox.bankSignatureSystem === "CUSTOM_APP" &&
        !props.doku.clientAcknowledgementNeeded &&
        tenant !== 'HU';
    var _s = useState(false), isArtificialSendToClient = _s[0], setIsArtificialSendToClient = _s[1];
    var configurations = useAppContext().configurations;
    var _t = useState(false), loading = _t[0], setLoading = _t[1];
    var Dragger = Upload.Dragger;
    var acceptedFileTypes = function () {
        switch (tenant) {
            case "HU":
                return ["pdf", "docx", "es3", "png", "jpg", "jpeg", "xlsx", "csv", "et3", "asice", "dosszie"];
            case "HR":
                return ["pdf", "docx", "png", "jpg", "jpeg", "xlsx", "csv", "asice", "msg"];
            case "RO":
                return ["pdf", "docx", "png", "jpg", "jpeg", "xlsx", "csv", "asice", "msg"];
            default:
                return ["pdf", "docx", "png", "jpg", "jpeg", "xlsx", "csv", "asice"];
        }
    };
    var getNotValidCertificatesFeatureFlagEnabledPerTenant = function () {
        switch (tenant) {
            case "RO":
                return configurations["ddeNotValidCertificatesEnabled_RO"];
            case "HR":
                return configurations["ddeNotValidCertificatesEnabled_HR"];
            case "BG":
                return configurations["ddeNotValidCertificatesEnabled_BG"];
            case "RS":
                return configurations["ddeNotValidCertificatesEnabled_RS"];
            case "CZ":
            case "SK":
                return configurations["ddeNotValidCertificatesEnabled_CZSK"];
            default:
                return configurations["ddeNotValidCertificatesEnabled"];
        }
    };
    useEffect(function () {
        var _a, _b;
        setUploadTransitionAvailable((_a = props.doku.dokuTransitions) === null || _a === void 0 ? void 0 : _a.some(function (value) { return value.triggerControl === "Upload"; }));
        setSendToArchiveTransitionAvailable((_b = props.doku.dokuTransitions) === null || _b === void 0 ? void 0 : _b.some(function (value) { return value.triggerControl === "SendToArchive"; }));
        setUnsavedActions(__spreadArray([], (props.doku.submitDokuStaticActions || []), true));
        setCurrentDokuAction(undefined);
        if (props.doku.dokuTransitions && props.doku.dokuTransitions.length > 0) {
            setDokuTransitions(props.doku.dokuTransitions
                .filter(function (value) {
                return value.triggerControl !== "Upload" &&
                    value.triggerControl !== "SignCallback" &&
                    (value.triggerControl !== "SendToArchive" || userHasRoleToArchive()) &&
                    ((value.triggerControl === "SendToBank" && tenant === "HU") || props.doku.dokuSetupCompleted);
            })
                .map(function (dokuTransition) {
                return {
                    id: dokuTransition.triggerControl,
                    text: formatDokuTransition(dokuTransition),
                    disabled: (needToShowSigningUser(props.userType, props.doku.status) &&
                        (dokuTransition.triggerControl === "SendForSigning" || dokuTransition.triggerControl === "SendForResigning") &&
                        props.doku.signingUsers &&
                        props.doku.signingUsers.some(function (user) {
                            return user.userType === "CLIENT" &&
                                ((props.dokuBox.clientSignatureSystem === "ESAW_DISPOSABLE" && isExpired(user.docExpiryDate)) ||
                                    (props.dokuBox.clientSignatureSystem === "ESAW_DISPOSABLE" && !user.docExpiryDate) ||
                                    (props.dokuBox.clientSignatureSystem === "ESAW_DISPOSABLE" && !user.docIssuerCountry) ||
                                    (czOrSkTenant() && !user.docIssuer) ||
                                    (czOrSkTenant() && user.docStolen) ||
                                    (bgTenant() && !user.docValid) ||
                                    (props.dokuBox.clientSignatureSystem === "ESAW_REMOTE" &&
                                        (emptyString(user.namirialUserId) || emptyString(user.namirialDeviceId))));
                        })) ||
                        (needToShowSigningUser(props.userType, props.doku.status) &&
                            (dokuTransition.triggerControl === "SendForSigning" ||
                                dokuTransition.triggerControl === "SendToBankForSigning" ||
                                dokuTransition.triggerControl === "SendForResigning") &&
                            props.doku.signingUsers &&
                            props.doku.signingUsers.some(function (user) {
                                return user.userType === "BANKER" &&
                                    props.dokuBox.bankSignatureSystem === "ESAW_REMOTE" &&
                                    (emptyString(user.namirialUserId) || emptyString(user.namirialDeviceId));
                            })) ||
                        (dokuTransition.triggerControl === "SendToArchive" && (!sendToArchivePossible || !archivePossible)) ||
                        (dokuTransition.triggerControl === "SendForArchiving" && !sendForArchivingPossible) ||
                        (dokuTransition.triggerControl === "SendToBank" &&
                            props.doku.status === DokuStatus.WAITING_CLIENT_FEEDBACK &&
                            props.doku.clientSignedDokuUploadNeeded) ||
                        (dokuTransition.triggerControl === "SendToClient" &&
                            props.doku.status === DokuStatus.WAITING_BANK_FEEDBACK &&
                            props.doku.bankSignedDokuUploadNeeded),
                };
            }));
        }
        else {
            setDokuTransitions([]);
        }
        if (sendToClientVisible && !props.doku.dokuTransitions.some(function (x) { return x.triggerControl === 'SendToClient'; })) {
            setIsArtificialSendToClient(true);
            var sendToClientObj = { triggerControl: 'SendToClient', sortOrder: 1 };
            var chipData_1 = { id: sendToClientObj.triggerControl, text: formatDokuTransition(sendToClientObj) };
            setDokuTransitions(function (dokuTransitions) { return __spreadArray(__spreadArray([], dokuTransitions, true), [chipData_1], false); });
        }
    }, [props.doku, props.doku.dokuTransitions, formatMessage, props.userType, sendForArchivingPossible, sendToArchivePossible, archivePossible]);
    useEffect(function () {
        var _a;
        setSendToArchivePossible(!((_a = props.doku.dokuStaticActions) === null || _a === void 0 ? void 0 : _a.includes("ValidateSignature")));
    }, [props.doku.status, props.doku.dokuStaticActions]);
    var userHasRoleToArchive = function () {
        var _a, _b;
        if (rsTenant()) {
            if (props.dokuBox.validatorRoleOptional) {
                return (_a = props.doku.participants) === null || _a === void 0 ? void 0 : _a.find(function (p) { return p.participantRole === ParticipantRole.BANK_RM && p.user.username === getLoggedInUserName(); });
            }
            else {
                return (_b = props.doku.participants) === null || _b === void 0 ? void 0 : _b.find(function (p) { return p.participantRole === ParticipantRole.BANK_VALIDATOR && p.user.username === getLoggedInUserName(); });
            }
        }
        else {
            return true;
        }
    };
    var addDokuStaticAction = function (doku, action, replace) {
        if (replace === void 0) { replace = false; }
        if (!doku.submitDokuStaticActions) {
            doku.submitDokuStaticActions = [];
        }
        if (replace) {
            var index = doku.submitDokuStaticActions.findIndex(function (value) { return value.dokuStaticAction === action.dokuStaticAction; });
            if (index > -1) {
                doku.submitDokuStaticActions.splice(index, 1);
            }
            doku.submitDokuStaticActions.push(action);
        }
        else {
            doku.submitDokuStaticActions.push(action);
        }
        setUnsavedActions(__spreadArray([], doku.submitDokuStaticActions, true));
    };
    var emptyString = function (value) {
        return !value || value.trim().length == 0;
    };
    var formatDokuTransition = function (dokuTransition) {
        if (dokuTransition && dokuTransition.triggerControl === "SendForSigning" && props.userType === UserType.BANKER) {
            return formatMessage("dde.doku.action." + dokuTransition.triggerControl + "." + UserType.CLIENT);
        }
        return formatMessage("dde.doku.action." + dokuTransition.triggerControl);
    };
    var setUniqueDokuStaticAction = function (doku, action) {
        removeDokuStaticAction(doku, action);
        doku.submitDokuStaticActions = __spreadArray(__spreadArray([], doku.submitDokuStaticActions, true), [action], false);
    };
    var removeDokuStaticAction = function (doku, action) {
        if (!doku.submitDokuStaticActions) {
            doku.submitDokuStaticActions = [];
        }
        var index = doku.submitDokuStaticActions.findIndex(function (value) { return value.dokuStaticAction === action.dokuStaticAction; });
        if (index < 0) {
            return;
        }
        doku.submitDokuStaticActions.splice(index, 1);
        setUnsavedActions(__spreadArray([], doku.submitDokuStaticActions, true));
    };
    var isForbiddenExtension = function (file) {
        var fileExtArr = file.name.split(".");
        var fileExt = fileExtArr[fileExtArr.length - 1];
        return !acceptedFileTypes().includes(String(fileExt).toLowerCase());
    };
    var getValidation = function (file) {
        function dokuInUploadState() {
            return props.doku.status === DokuStatus.WAITING_BANK_UPLOAD || props.doku.status === DokuStatus.UPLOADED_BANK || props.doku.status === DokuStatus.WAITING_CLIENT_UPLOAD || props.doku.status === DokuStatus.UPLOADED_CLIENT;
        }
        if (user.userType !== "CLIENT" && dokuInUploadState()) {
            var data = {
                fileName: file.name,
                fileContent: "file",
                dokuFlowTypeId: props.doku.dokuFlowTypeId ? props.doku.dokuFlowTypeId : 0
            };
            var formData = new FormData();
            formData.append("request", new Blob([JSON.stringify(data)], { type: "application/json" }));
            formData.append("files", file);
            var apiConfig = { data: formData };
            executeAxios({
                params: __assign(__assign({}, isDocumentSignedAPI), apiConfig),
                onSuccess: function (resp) {
                    return getFile(file);
                },
                onLoading: setLoading,
                onFail: function (resp) { return showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatServerError(resp, formatMessage),
                }); },
            });
        }
        else {
            return getFile(file);
        }
    };
    var getFile = function (file) {
        if (isForbiddenExtension(file)) {
            showErrorMessage({
                title: formatMessage("dde.error.errorTitle"),
                message: formatMessage("dde.error.fileupload.forbidden.extenstion"),
            });
            return Upload.LIST_IGNORE;
        }
        var isLt10M = file.size / 1024 / 1024 > 10;
        if (isLt10M) {
            showErrorMessage({
                title: formatMessage("dde.error.errorTitle"),
                message: formatMessage("dde.error.file.upload.maxsize", { maxSize: 10 }),
            });
            return Upload.LIST_IGNORE;
        }
        var isEmpty = file.size === 0;
        if (isEmpty) {
            showErrorMessage({
                title: formatMessage("dde.error.errorTitle"),
                message: formatMessage("dde.error.file.upload.empty"),
            });
            return Upload.LIST_IGNORE;
        }
        setShowUploadSpinner(true);
        setUploaded(false);
        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = function (evt) {
            if (evt.target && evt.target.readyState === FileReader.DONE && evt.target.result) {
                if (uploadTransitionAvailable || props.doku.addOtherDokuId) {
                    props.doku.dokuAction = "Upload";
                    setUniqueDokuStaticAction(props.doku, {
                        dokuId: props.doku.id ? props.doku.id : 0,
                        dokuStaticAction: "Upload",
                        actionData: {
                            fileName: file === null || file === void 0 ? void 0 : file.name,
                        },
                        fileContent: file,
                    });
                    props.onSubmitUpload(props.doku);
                }
                else {
                    addDokuStaticAction(props.doku, {
                        dokuId: props.doku.id ? props.doku.id : 0,
                        dokuStaticAction: "Upload",
                        actionData: {
                            fileName: file === null || file === void 0 ? void 0 : file.name,
                        },
                        fileContent: file,
                    }, !(props.doku.isReconfiguredAdded === true));
                    removeReconfigureRemoveAction(props.doku);
                    if (props.doku.reconfigured && !props.doku.fileName) {
                        props.onSubmitUpload(props.doku);
                    }
                    else if (sendToClientVisible && !getNotValidCertificatesFeatureFlagEnabledPerTenant()) {
                        checkFileValidity(file);
                    }
                }
                if (file && file.name && file.name !== props.doku.fileName) {
                    setUploadedFile(file.name);
                }
                setShowUploadSpinner(false);
                setShowUploadPlaceholder(false);
                setUploaded(true);
            }
        };
        return false;
    };
    var checkFileValidity = function (file) {
        var formData = new FormData();
        formData.append("file", file);
        var apiConfig = {
            url: "/dde/dokubox/doku/".concat(props.doku.id, "/check-file-validity-on-upload"),
            method: "POST",
        };
        var request = {
            data: formData,
        };
        executeAxios({
            params: __assign(__assign({}, apiConfig), request),
            onSuccess: function (response) {
                setIsArtificialSendToClient(!response);
            },
            onLoading: setLoading,
            onFail: function (resp) {
                return showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatServerError(resp, formatMessage),
                });
            },
        });
    };
    var changeDokuAction = function (action) {
        props.doku.dokuAction = action;
        setCurrentDokuAction(action);
        props.onDokuActionSelected(action);
        if (action === 'SendToClient' && sendToClientVisible && !getNotValidCertificatesFeatureFlagEnabledPerTenant() && isArtificialSendToClient) {
            showErrorMessage({
                title: formatMessage("dde.error.errorTitle"),
                message: formatMessage("dde.banker.manageDokuBox.doku.notarchivable"),
            });
        }
    };
    var downloadFile = function (event) {
        event.preventDefault();
        var apiConfig = {
            url: "/dde/dokubox/doku/" + props.doku.id + "/download",
            method: "GET",
        };
        executeAxios({
            params: __assign({}, apiConfig),
            onSuccess: function (response) {
                downloadFile1(response.fileName, response.fileContent);
            },
            onLoading: function () { },
            onFail: function (resp) {
                return showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatServerError(resp, formatMessage),
                });
            },
        });
        return false;
    };
    var downloadFile1 = function (fileName, data) {
        var linkSource = "data:application/octet-stream;base64," + data;
        var downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.target = "_blank";
        downloadLink.click();
    };
    var edsRootURL = useAppContext().configurations.edsRootURL;
    var openEDS = function () {
        var anchor = document.createElement("a");
        anchor.href = "".concat(edsRootURL, "/Document/Upload?id=").concat(props.dokuBox.edsProcessId);
        anchor.target = "_blank";
        anchor.click();
        props.closeDokuBox();
    };
    function getUserFullName() {
        var fullName;
        if (user && user.userType) {
            if (UserType.BANKER === user.userType) {
                fullName = user.displayName;
            }
            else {
                fullName = user.firstName + " " + user.lastName;
            }
        }
        return fullName;
    }
    var needToShowSigningUser = function (userType, status) {
        if (rsTenant()) {
            return false;
        }
        if (bgTenant() && UserType.CLIENT === userType) {
            return false;
        }
        return ((UserType.BANKER === userType && (status === DokuStatus.UPLOADED_BANK || status === DokuStatus.UNDER_NEGOTIATION_BANK)) ||
            status === DokuStatus.WAITING_BANK_FEEDBACK ||
            status === DokuStatus.WAITING_BANK_SIGNATURE ||
            status === DokuStatus.WAITING_ARCHIVAL);
    };
    var isExpired = function (date) {
        return date && moment(date).isBefore(moment.now());
    };
    var isExpireSoon = function (date) {
        return date && moment(date).isBefore(moment(moment.now()).add(1, "months"));
    };
    var getDisplayName = function (signingUser) {
        switch (getLocaleFromLocalStorage()) {
            case "bg": return ((signingUser.firstNameLocal || "") + " " + (signingUser.lastNameLocal || "")).trim();
            case "hu": return ((signingUser.lastName || "") + " " + (signingUser.firstName || "")).trim();
            default: return ((signingUser.firstName || "") + " " + (signingUser.lastName || "")).trim();
        }
    };
    var formatDate = function (date) {
        return date ? moment(date).format(dateFormat) : "N/A";
    };
    var isDownloadVisible = function () {
        var _a;
        return props.doku.fileName && props.doku.downloadable && ((_a = props.doku.dokuStaticActions) === null || _a === void 0 ? void 0 : _a.includes("View"));
    };
    var hasReconfigureRemoveAction = unsavedActions.some(function (x) { var _a; return x.dokuStaticAction === "Reconfigure" && ((_a = x.actionData) === null || _a === void 0 ? void 0 : _a.usedInBox) === false; });
    var hasUploadStaticAction = unsavedActions.some(function (x) { return x.dokuStaticAction === "Upload"; });
    var addReconfigureRemoveAction = function (doku) {
        var _a;
        var action = ((_a = doku.submitDokuStaticActions) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.dokuStaticAction === "Reconfigure"; })) ||
            {
                dokuId: props.doku.id,
                dokuStaticAction: "Reconfigure",
            };
        action.actionData = action.actionData || { dokuId: props.doku.id };
        action.actionData.usedInBox = false;
        addDokuStaticAction(doku, action, true);
    };
    var removeReconfigureRemoveAction = function (doku) {
        var _a, _b;
        var action = (_a = doku.submitDokuStaticActions) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.dokuStaticAction === "Reconfigure"; });
        if (((_b = action === null || action === void 0 ? void 0 : action.actionData) === null || _b === void 0 ? void 0 : _b.usedInBox) === false) {
            if (action.actionData.bankParticipants || action.actionData.clientParticipants) {
                action.actionData.usedInBox = true;
                setUnsavedActions(__spreadArray([], doku.submitDokuStaticActions, true));
            }
            else {
                removeDokuStaticAction(doku, action);
            }
        }
    };
    var uploadProps = {
        name: "file".concat(props.doku.id),
        multiple: false,
        beforeUpload: getValidation,
        accept: acceptedFileTypes()
            .map(function (type) { return "." + type; })
            .join(", "),
        itemRender: function (originNode, file) {
            return (_jsx(_Fragment, {}));
        },
        maxCount: 1,
        onChange: function (uploadChangeParams) {
            props.onFileChange(props.doku.id || 0);
            if (uploadChangeParams.fileList && uploadChangeParams.fileList.length > 0) {
                var anyForbiddenExtension = false;
                for (var index = 0; index < uploadChangeParams.fileList.length; index++) {
                    var file = uploadChangeParams.fileList[index];
                    if (isForbiddenExtension(file)) {
                        anyForbiddenExtension = true;
                    }
                }
                if (anyForbiddenExtension) {
                    showErrorMessage({
                        title: formatMessage("dde.error.errorTitle"),
                        message: formatMessage("dde.error.fileupload.forbidden.extenstion"),
                    });
                }
            }
            else {
                showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatMessage("dde.error.fileupload.invalid.content"),
                });
            }
        },
        onDrop: function (e) {
            props.onFileChange(props.doku.id || 0);
            if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                var anyForbiddenExtension = false;
                for (var index = 0; index < e.dataTransfer.files.length; index++) {
                    var file = e.dataTransfer.files.item(index);
                    if (isForbiddenExtension(file)) {
                        anyForbiddenExtension = true;
                    }
                }
                if (anyForbiddenExtension) {
                    showErrorMessage({
                        title: formatMessage("dde.error.errorTitle"),
                        message: formatMessage("dde.error.fileupload.forbidden.extenstion"),
                    });
                }
            }
            else {
                showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatMessage("dde.error.fileupload.invalid.content"),
                });
            }
        },
    };
    var czOrSkTenant = function () {
        return tenant === "CZ" || tenant === "SK";
    };
    var rsTenant = function () {
        return tenant === "RS";
    };
    var bgTenant = function () {
        return tenant === "BG";
    };
    var roTenant = function () {
        return tenant === "RO";
    };
    var hrTenant = function () {
        return tenant === "HR";
    };
    function showFinalizeForClientAddedDoku() {
        var _a;
        return (!props.readOnly &&
            !hasReconfigureRemoveAction &&
            props.doku.id &&
            !props.doku.addOtherDokuId &&
            !props.doku.dokuSetupCompleted &&
            (czOrSkTenant() || rsTenant() || bgTenant() || hrTenant()
                ? ((_a = props.doku.initiatedBy) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "client"
                : !props.doku.dokuDefinitionId) &&
            props.userType === UserType.BANKER);
    }
    function addOtherDokuCanUpload() {
        return ((props.doku.addOtherDokuId || props.doku.isReconfiguredAdded) &&
            ((props.userType === UserType.BANKER && props.doku.status === DokuStatus.WAITING_BANK_UPLOAD) || props.userType === UserType.CLIENT));
    }
    var setIsVisibleElectronicSignatureDlg = useCallback(function (visible) {
        setIsDokuValidateSignatureDlgVisible(visible);
        setSendToArchivePossible(true);
    }, []);
    var setValidDoku = useCallback(function (isValid) {
        setArchivePossible(isValid);
        setSendForArchivingPossible(isValid);
    }, []);
    function downloadDisabled() {
        return roTenant() && sendToArchiveTransitionAvailable && (!sendToArchivePossible || !archivePossible);
    }
    var isUploadVisible = function () {
        var _a;
        return !props.readOnly && !hasReconfigureRemoveAction &&
            !(props.doku.status === "WAITING_BANK_SIGNATURE_AUX" && props.dokuBox.bankSignatureSystem !== "CUSTOM_APP") &&
            (((_a = props.doku.dokuStaticActions) === null || _a === void 0 ? void 0 : _a.includes("Upload")) || addOtherDokuCanUpload());
    };
    function renderJumpToEds() {
        return _jsx(_Fragment, { children: _jsx(ActionButtonWrapperStyled, { children: _jsx(Button, __assign({ type: "text", onClick: openEDS, style: { marginLeft: "20px" } }, { children: _jsxs(Space, __assign({ direction: "horizontal" }, { children: [_jsx(SignatureIcon, {}), formatMessage("dde.doku.card.jumpToEds")] })) })) }) });
    }
    function renderValidateSignatures() {
        return _jsx(_Fragment, { children: _jsx(ActionButtonWrapperStyled, { children: _jsx(Button, __assign({ type: "text", onClick: function () {
                        setIsDokuValidateSignatureDlgVisible(true);
                    }, style: { color: '#007A91' } }, { children: _jsxs(Space, __assign({ direction: "horizontal" }, { children: [_jsx(SignatureIcon, { fill: '#007A91' }), formatMessage("dde.doku.validateSignature.button")] })) })) }) });
    }
    function renderDownloadButton() {
        return _jsx(_Fragment, { children: (function () {
                return _jsx(DownloadIcon, {});
            })() });
    }
    function dokuDeleted() {
        var _a;
        return props.dokuMap && props.dokuMap.get(props.index) && ((_a = props.dokuMap.get(props.index)) === null || _a === void 0 ? void 0 : _a.removed);
    }
    var description = _jsx(_Fragment, { children: getByLocale(props.doku.instructionEn, props.doku.instructionLocal) });
    function getFileName() {
        if (uploadedFile) {
            return uploadedFile;
        }
        else {
            return props.doku.fileName;
        }
    }
    var computeFileNameAndExtension = function (file) {
        if (file) {
            var dotIndex = file.lastIndexOf('.');
            if (dotIndex === -1) {
                return { name: file, extension: '' };
            }
            return {
                name: file.substring(0, dotIndex),
                extension: file.substring(dotIndex)
            };
        }
    };
    function renderFileName() {
        var _a, _b;
        return _jsx("div", __assign({ className: "file-name" }, { children: _jsxs(FileNameContainer, __assign({}, (isDownloadVisible() && !downloadDisabled() && { style: { cursor: 'pointer' }, onClick: downloadFile }), { children: [!showUploadSpinner && isUploaded && ((props.failedUpload.get(props.doku.id || 0))) ? (_jsx(UnsuccessSmallIcon, { style: { marginRight: '5px' }, fill: "#D73928", width: "25px", height: "25px" })) : (_jsx(Icon, { children: _jsx(StyledPaperClipIcon, {}) })), _jsx(Tooltip, __assign({ overlayStyle: { maxWidth: "500px" }, overlayInnerStyle: { whiteSpace: "break-spaces" }, title: getFileName() }, { children: _jsxs(FileNameWrapper, { children: [_jsx(FileName, { children: (_a = computeFileNameAndExtension(getFileName())) === null || _a === void 0 ? void 0 : _a.name }), _jsx(FileExtension, { children: (_b = computeFileNameAndExtension(getFileName())) === null || _b === void 0 ? void 0 : _b.extension })] }) })), _jsx(StyledDownloadIcon, { children: isDownloadVisible() &&
                            renderDownloadButton() })] })) }));
    }
    function validateSignaturesVisible() {
        var _a;
        return !hasReconfigureRemoveAction && ((_a = props.doku.dokuStaticActions) === null || _a === void 0 ? void 0 : _a.includes("ValidateSignature"));
    }
    function jumpToEdsVisible() {
        var _a;
        return !hasReconfigureRemoveAction &&
            tenant === "HU" &&
            ((_a = props.doku.dokuStaticActions) === null || _a === void 0 ? void 0 : _a.includes("JumpToEds")) &&
            hasAnyOfTheRoles(["DOKU_JUMP_TO_EDS"]);
    }
    return (_jsxs(_Fragment, { children: [loading && _jsx(Spinner, {}), _jsxs(DokuPanelWrapper, __assign({ "$isDeleted": dokuDeleted() }, { children: [dokuDeleted() ? (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(Typography.Text, __assign({ style: { textTransform: 'uppercase', fontWeight: 'bold' } }, { children: formatMessage("dde.doku.card.deleteText1") })) }), _jsx("div", { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.deleteText2") }) }), _jsx("br", {}), _jsx("div", { children: _jsx(UndoDeleteButton, __assign({ type: "primary", onClick: function (e) {
                                        props.doku.removed = false;
                                        removeReconfigureRemoveAction(props.doku);
                                        props.updateDeleteMap(props.index, props.doku);
                                    } }, { children: formatMessage("dde.doku.undoDelete") })) })] })) : (_jsxs(_Fragment, { children: [description && (_jsx(Description, { children: _jsx(Typography.Text, { children: description }) })), configurations["is.doku.progress.visible"] && _jsx(DokuPanelBodyProgressBar, { doku: props.doku, dokuBox: props.dokuBox, inTheModal: false }), _jsxs("div", { children: [_jsxs(DraggerWrapperFlex, { children: [!showUploadSpinner && (props.doku.fileName || uploadedFile) && (_jsx(_Fragment, { children: !isUploadVisible() && !jumpToEdsVisible() && !validateSignaturesVisible() && !showFinalizeForClientAddedDoku() ? (_jsx(Container, { children: renderFileName() })) : (_jsx(HalfContainer, { children: renderFileName() })) })), _jsxs("div", __assign({ style: { paddingLeft: "10px", flex: 1, display: "flex", justifyContent: "flex-end" } }, { children: [isUploadVisible() && (_jsxs("div", __assign({ style: { width: "100%" } }, { children: [description && (_jsx("br", {})), _jsxs(Form.Item, { children: [_jsxs(Dragger, __assign({}, uploadProps, { style: { width: "100%" } }, { children: [_jsx(Typography.Text, __assign({ strong: true, style: {
                                                                                    color: "#007A91",
                                                                                    textTransform: "uppercase",
                                                                                    fontSize: "14px",
                                                                                } }, { children: formatMessage("dde.doku.card.browseFile") })), _jsx("br", {}), _jsx(Typography.Text, { children: formatMessage("dde.doku.card.dragFile") })] })), _jsxs(UploadStatusStyled, { children: [showUploadSpinner && _jsx(Loading3QuartersOutlined, { spin: true }), !props.doku.fileName && showUploadPlaceholder && (_jsx(Typography.Text, { children: formatMessage("dde.doku.card.noFileUpload") }))] })] })] }))), validateSignaturesVisible() && !isUploadVisible() &&
                                                        renderValidateSignatures(), jumpToEdsVisible() &&
                                                        !isUploadVisible() &&
                                                        renderJumpToEds(), showFinalizeForClientAddedDoku() && !isUploadVisible() && (_jsx(_Fragment, { children: _jsx(ActionButtonWrapperStyled, { children: _jsx(Button, __assign({ type: "text", onClick: function () { return props.onFinalize(props.doku); } }, { children: formatMessage("dde.dde.manageDokuBox.Finalize") })) }) }))] }))] }), isUploadVisible() && (_jsx("div", __assign({ style: {
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            maxWidth: "100%",
                                            marginTop: "-5px",
                                            marginBottom: "10px",
                                        } }, { children: _jsxs("div", { children: [validateSignaturesVisible() &&
                                                    renderValidateSignatures(), jumpToEdsVisible() &&
                                                    renderJumpToEds(), showFinalizeForClientAddedDoku() && (_jsx(_Fragment, { children: _jsx(ActionButtonWrapperStyled, { children: _jsx(Button, __assign({ type: "text", onClick: function () { return props.onFinalize(props.doku); } }, { children: formatMessage("dde.dde.manageDokuBox.Finalize") })) }) }))] }) })))] }), needToShowSigningUser(props.userType, props.doku.status) && (_jsx(_Fragment, { children: _jsxs("div", { children: [props.dokuBox.clientSignatureSystem === "ESAW_DISPOSABLE" &&
                                            props.doku.signingUsers &&
                                            props.doku.signingUsers
                                                .filter(function (user) { return user.userType === "CLIENT"; })
                                                .map(function (user) {
                                                if (isExpired(user.docExpiryDate)) {
                                                    return (_jsxs(SigningClientUserRe, __assign({ className: "expired" }, { children: [_jsx(CloseIcon, {}), " ", _jsx(Typography.Text, { children: formatMessage("dde.doku.card.expiredSigner", {
                                                                    fullName: getDisplayName(user),
                                                                    date: formatDate(user.docExpiryDate),
                                                                }) })] }), user.namirialUserId));
                                                }
                                                else if (czOrSkTenant() && user.docStolen) {
                                                    return (_jsxs(SigningClientUserRe, __assign({ className: "expired" }, { children: [_jsx(CloseIcon, {}), " ", _jsx(Typography.Text, { children: formatMessage("dde.doku.card.stolenSigner", {
                                                                    fullName: getDisplayName(user),
                                                                    date: formatDate(user.docExpiryDate),
                                                                }) })] })));
                                                }
                                                else if (bgTenant() && !user.docValid) {
                                                    return (_jsxs(SigningClientUserRe, __assign({ className: "expired" }, { children: [_jsx(CloseIcon, {}), " ", _jsx(Typography.Text, { children: formatMessage("dde.doku.card.invalidSigner", {
                                                                    fullName: getDisplayName(user),
                                                                }) })] })));
                                                }
                                                else if (isExpireSoon(user.docExpiryDate)) {
                                                    return (_jsxs(SigningClientUserRe, __assign({ className: "expire-soon" }, { children: [_jsx(UnsuccessSmallIcon, {}), " ", _jsx(Typography.Text, { children: formatMessage("dde.doku.card.expireSoonSigner", {
                                                                    fullName: getDisplayName(user),
                                                                    date: formatDate(user.docExpiryDate),
                                                                }) })] })));
                                                }
                                                else {
                                                    return (_jsxs(SigningClientUserRe, __assign({ className: "not-expired" }, { children: [_jsx(SuccessSmallIcon, {}), " ", _jsx(Typography.Text, { children: formatMessage("dde.doku.card.notExpiredSigner", {
                                                                    fullName: getDisplayName(user),
                                                                    date: formatDate(user.docExpiryDate),
                                                                }) })] })));
                                                }
                                            }), props.dokuBox.clientSignatureSystem === "ESAW_DISPOSABLE" &&
                                            props.doku.signingUsers &&
                                            props.doku.signingUsers.find(function (user) { return user.userType === "CLIENT" && !user.docIssuerCountry; }) &&
                                            props.doku.dokuTransitions &&
                                            props.doku.dokuTransitions.find(function (dokuTransition) { return dokuTransition.triggerControl === "SendForSigning" || dokuTransition.triggerControl === "SendForResigning"; }) && (_jsx(InvalidSigningUsers, { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.missingDocIssuerCountry", {
                                                    names: props.doku.signingUsers
                                                        .filter(function (user) { return user.userType === "CLIENT" && !user.docIssuerCountry; })
                                                        .map(function (user) { return getDisplayName(user); })
                                                        .join(", "),
                                                }) }) })), czOrSkTenant() &&
                                            props.doku.signingUsers &&
                                            props.doku.signingUsers.find(function (user) { return user.userType === "CLIENT" && !user.docIssuer; }) &&
                                            props.doku.dokuTransitions &&
                                            props.doku.dokuTransitions.find(function (dokuTransition) { return dokuTransition.triggerControl === "SendForSigning" || dokuTransition.triggerControl === "SendForResigning"; }) && (_jsx(InvalidSigningUsers, { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.missingDocIssuer", {
                                                    names: props.doku.signingUsers
                                                        .filter(function (user) { return user.userType === "CLIENT" && !user.docIssuer; })
                                                        .map(function (user) { return getDisplayName(user); })
                                                        .join(", "),
                                                }) }) })), czOrSkTenant() &&
                                            props.doku.signingUsers &&
                                            props.doku.signingUsers.find(function (user) { return user.userType === "CLIENT" && user.docStolen; }) &&
                                            props.doku.dokuTransitions &&
                                            props.doku.dokuTransitions.find(function (dokuTransition) { return dokuTransition.triggerControl === "SendForSigning" || dokuTransition.triggerControl === "SendForResigning"; }) && (_jsx(InvalidSigningUsers, { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.docStolen", {
                                                    names: props.doku.signingUsers
                                                        .filter(function (user) { return user.userType === "CLIENT" && user.docStolen; })
                                                        .map(function (user) { return getDisplayName(user); })
                                                        .join(", "),
                                                }) }) })), props.dokuBox.clientSignatureSystem === "ESAW_DISPOSABLE" &&
                                            props.doku.signingUsers &&
                                            props.doku.signingUsers.find(function (user) { return user.userType === "CLIENT" && !user.docExpiryDate; }) &&
                                            props.doku.dokuTransitions &&
                                            props.doku.dokuTransitions.find(function (dokuTransition) { return dokuTransition.triggerControl === "SendForSigning" || dokuTransition.triggerControl === "SendForResigning"; }) && (_jsx(InvalidSigningUsers, { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.missingDocExpiryDate", {
                                                    names: props.doku.signingUsers
                                                        .filter(function (user) { return user.userType === "CLIENT" && !user.docExpiryDate; })
                                                        .map(function (user) { return getDisplayName(user); })
                                                        .join(", "),
                                                }) }) })), props.doku.signingUsers &&
                                            props.doku.signingUsers.find(function (user) {
                                                return user.userType === "BANKER" &&
                                                    props.dokuBox.bankSignatureSystem === "ESAW_REMOTE" &&
                                                    (emptyString(user.namirialUserId) || emptyString(user.namirialDeviceId));
                                            }) &&
                                            props.doku.dokuTransitions &&
                                            props.doku.dokuTransitions.find(function (dokuTransition) {
                                                return dokuTransition.triggerControl === "SendForSigning" ||
                                                    dokuTransition.triggerControl === "SendToBankForSigning" ||
                                                    dokuTransition.triggerControl === "SendForResigning";
                                            }) && (_jsx(InvalidSigningUsers, { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.missingNamirialSettings", {
                                                    names: props.doku.signingUsers
                                                        .filter(function (user) {
                                                        return user.userType === "BANKER" &&
                                                            props.dokuBox.bankSignatureSystem === "ESAW_REMOTE" &&
                                                            (emptyString(user.namirialUserId) || emptyString(user.namirialDeviceId));
                                                    })
                                                        .map(function (user) { return getDisplayName(user); })
                                                        .join(", "),
                                                }) }) })), props.doku.signingUsers &&
                                            props.doku.signingUsers.find(function (user) {
                                                return user.userType === "CLIENT" &&
                                                    props.dokuBox.clientSignatureSystem === "ESAW_REMOTE" &&
                                                    (emptyString(user.namirialUserId) || emptyString(user.namirialDeviceId));
                                            }) &&
                                            props.doku.dokuTransitions &&
                                            props.doku.dokuTransitions.find(function (dokuTransition) {
                                                return dokuTransition.triggerControl === "SendForSigning" ||
                                                    dokuTransition.triggerControl === "ContinueToSigning" ||
                                                    dokuTransition.triggerControl === "SendForResigning";
                                            }) && (_jsx(InvalidSigningUsers, { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.missingClientNamirialSettings", {
                                                    names: props.doku.signingUsers
                                                        .filter(function (user) {
                                                        return user.userType === "CLIENT" &&
                                                            props.dokuBox.clientSignatureSystem === "ESAW_REMOTE" &&
                                                            (emptyString(user.namirialUserId) || emptyString(user.namirialDeviceId));
                                                    })
                                                        .map(function (user) { return getDisplayName(user); })
                                                        .join(", "),
                                                }) }) }))] }) })), _jsx(DokuAdditionalDataDiv, { children: _jsx(DokuActionAdditionalDataTenant, { form: props.form, doku: props.doku, dokuBox: props.dokuBox, readOnly: props.readOnly, userType: props.userType, currentDokuAction: currentDokuAction, registerValidation: props.registerValidation }) }), !props.readOnly &&
                                (props.doku.dokuSetupCompleted ||
                                    (!props.doku.dokuSetupCompleted && props.userType === UserType.CLIENT && props.doku.status === DokuStatus.UPLOADED_CLIENT)) &&
                                dokuTransitions &&
                                dokuTransitions.length > 0 && (_jsx(_Fragment, { children: _jsxs(RadioButtonContainer, { children: [_jsx(Radio.Group, __assign({ onChange: function (value) {
                                                changeDokuAction(value.target.value);
                                            }, style: { width: '80%' }, value: currentDokuAction }, { children: dokuTransitions.map(function (opt) {
                                                return (_jsx(StyledRadio, { value: opt.id, disabled: opt.disabled, label: opt.text }, opt.id));
                                            }) })), currentDokuAction !== undefined && (_jsx(Button, __assign({ type: "text", icon: _jsx(CloseIcon, { fill: '#007A91', style: { width: '12.8px', height: '12.8px' } }), onClick: function () {
                                                setCurrentDokuAction(undefined);
                                                props.doku.dokuAction = undefined;
                                            }, style: { color: '#007A91', fontSize: '14px', fontWeight: 'bold' } }, { children: formatMessage("dde.doku.card.radioClear").toUpperCase() })))] }) }))] })), validateSignaturesVisible() && props.doku.id && (_jsx(ElectronicSignatureTenant, { doku: props.doku, isVisible: isDokuValidateSignatureDlgVisible, setIsVisible: setIsVisibleElectronicSignatureDlg, setValidDoku: setValidDoku }))] }))] }));
};
export default DokuPanelBody;
