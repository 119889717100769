var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Modal, SuccessSmallIcon, Table, WaitLargeIcon, InfoLargeIcon } from "@g4p/react-styleguide-library";
import DokuPanelBodyProgressBar, { isEsawDokuBox } from "../DokuCard/DokuPanelBodyProgressBar";
import { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { DokuBoxNamirialEnvelopeStatus, DokuStatus } from "../../admin/DokuBoxList/services/domain";
import { DokuBoxModalTableStyle, ColumnContainer, SubTableStyle, LabelFinalized, LabelCanceled, LabelNotRequired, LablePending, TooltipText, ToolTipBox, } from "./DokuBoxProgressModalStyle";
import { orderParticipant } from "./DokuBoxProgressOrder";
import { Tooltip } from "antd";
import { useTenantContext } from "../../../services/tenantContext";
import { getLocaleFromLocalStorage } from "../../../services/appStorage";
import { useFormatMessage } from "../../../services/formatMessageHook";
var DokuBoxProgressModal = function (props) {
    var _a = useState([]), expandedRowKeys = _a[0], setExpandedRowKeys = _a[1];
    var tenant = useTenantContext().tenant;
    var formatMessage = useFormatMessage().formatMessage;
    var beforeClientSignatureStatuses = [DokuStatus.WAITING_CLIENT_SIGNATURE, DokuStatus.WAITING_BANK_SIGNATURE,
        DokuStatus.WAITING_BANK_SIGNATURE_AUX, DokuStatus.WAITING_ARCHIVAL, DokuStatus.ARCHIVED];
    var afterClientSignatureStatuses = [DokuStatus.WAITING_BANK_SIGNATURE,
        DokuStatus.WAITING_BANK_SIGNATURE_AUX, DokuStatus.WAITING_ARCHIVAL, DokuStatus.ARCHIVED];
    var afterBankSignatureStatuses = [DokuStatus.WAITING_BANK_SIGNATURE_AUX, DokuStatus.WAITING_ARCHIVAL, DokuStatus.ARCHIVED];
    var columns = [
        {
            dataIndex: "dokuName",
            key: "dokuName",
            align: "left",
            render: function (value, record, index) {
                return (_jsxs(ColumnContainer, { children: [_jsxs("span", { children: [index + 1, ". ", record.titleEn] }), (props.dokuBox.clientSignatureSystem === "NA" && props.dokuBox.bankSignatureSystem === "NA") ?
                            _jsx(LabelNotRequired, { children: formatMessage("dde.doku.progress.modal.signing.not.required") })
                            : (record.status === DokuStatus.WAITING_ARCHIVAL
                                || record.status === DokuStatus.UNDER_ARCHIVING
                                || record.status === DokuStatus.ARCHIVED) ?
                                props.dokuBox.namirialEnvelopeStatus === DokuBoxNamirialEnvelopeStatus.FINALIZED ?
                                    _jsx(LabelFinalized, { children: formatMessage("dde.doku.progress.modal.signed") })
                                    :
                                        props.dokuBox.namirialEnvelopeStatus === DokuBoxNamirialEnvelopeStatus.REJECTED
                                            ?
                                                _jsx(LabelCanceled, { children: record.status }) :
                                            !isEsawDokuBox(props.dokuBox) ? _jsx(LabelFinalized, { children: formatMessage("dde.doku.progress.modal.signed") }) : ""
                                : _jsx(LablePending, { children: record.status })] }));
            },
        },
        {
            dataIndex: "progress",
            key: "progress",
            align: "right",
            render: function (value, record) { return _jsx(DokuPanelBodyProgressBar, { doku: record, dokuBox: props.dokuBox, inTheModal: true }); },
        },
    ];
    var subColumns = [
        {
            title: "PARTICIPANT TYPE",
            dataIndex: "participantType",
            key: "participantType",
            render: function (value, record) { return _jsx("span", { children: record.participantType }); },
        },
        {
            title: "NAME",
            dataIndex: "name",
            key: "name",
            render: function (value, record) { return _jsxs(ColumnContainer, { children: [_jsx("span", { children: record.user.fullName }), _jsx(Tooltip, __assign({ title: _jsxs(ToolTipBox, { children: [_jsx(TooltipText, { children: record.user.fullName }), _jsx(TooltipText, { children: record.user.email }), _jsx(TooltipText, { children: record.user.phoneNumber })] }) }, { children: _jsx(InfoLargeIcon, { style: { paddingLeft: "5px" }, height: 16, width: 16 }) }))] }); },
        },
        {
            title: "ROLE",
            dataIndex: "participantRole",
            key: "participantRole",
            render: function (value, record) { return _jsx("span", { children: record.participantRole }); },
        },
        {
            title: "COMPANY",
            dataIndex: "company",
            key: "company",
            render: function (value, record) {
                return _jsx("span", { children: record.participantType === "BANK" ? tenant === "BG" ?
                        getLocaleFromLocalStorage().toUpperCase() === "EN" ? "UniCredit Bulbank" : "УниКредит Булбанк"
                        : "Unicredit" : props.dokuBox.clientName });
            },
        },
        {
            title: "SIGNED",
            dataIndex: "signed",
            key: "signed",
            render: function (value, record) {
                var _a;
                var status = (_a = props.dokuBox.dokus.find(function (doku) { return doku.id === record.dokuID; })) === null || _a === void 0 ? void 0 : _a.status;
                if (status !== undefined) {
                    if (isEsawDokuBox(props.dokuBox)) {
                        if (!beforeClientSignatureStatuses.includes(status)) {
                            return _jsx(WaitLargeIcon, { height: 16, width: 16 });
                        }
                        else {
                            if (record.participantType === "BANK" && status === DokuStatus.WAITING_CLIENT_SIGNATURE) {
                                return _jsx(WaitLargeIcon, { height: 16, width: 16 });
                            }
                            else {
                                if (record.participantType === "CLIENT") {
                                    if (status === DokuStatus.WAITING_CLIENT_SIGNATURE) {
                                        if (record.participantState === "TODO") {
                                            if (record.signingUrlResolved === true) {
                                                return _jsx("span", { children: formatMessage("dde.doku.progress.modal.signing.already.started") });
                                            }
                                            else {
                                                return _jsx(WaitLargeIcon, { height: 16, width: 16 });
                                            }
                                        }
                                        else {
                                            return _jsx(SuccessSmallIcon, { fill: "#3d7d43", height: 16, width: 16 });
                                        }
                                    }
                                    else if (afterClientSignatureStatuses.includes(status)) {
                                        if (record.participantState === "TODO") {
                                            return _jsx("span", { children: formatMessage("dde.doku.progress.modal.not.required") });
                                        }
                                        else {
                                            return _jsx(SuccessSmallIcon, { fill: "#3d7d43", height: 16, width: 16 });
                                        }
                                    }
                                }
                                else {
                                    if (status === DokuStatus.WAITING_BANK_SIGNATURE) {
                                        if (record.participantState === "TODO") {
                                            if (record.signingUrlResolved === true) {
                                                return _jsx("span", { children: formatMessage("dde.doku.progress.modal.signing.already.started") });
                                            }
                                            else {
                                                return _jsx(WaitLargeIcon, { height: 16, width: 16 });
                                            }
                                        }
                                        else {
                                            return _jsx(SuccessSmallIcon, { fill: "#3d7d43", height: 16, width: 16 });
                                        }
                                    }
                                    else if (afterBankSignatureStatuses.includes(status)) {
                                        if (record.participantState === "TODO") {
                                            return _jsx("span", { children: formatMessage("dde.doku.progress.modal.not.required") });
                                        }
                                        else {
                                            return _jsx(SuccessSmallIcon, { fill: "#3d7d43", height: 16, width: 16 });
                                        }
                                    }
                                }
                            }
                        }
                    }
                    else {
                        if (!beforeClientSignatureStatuses.includes(status)) {
                            return _jsx(WaitLargeIcon, { height: 16, width: 16 });
                        }
                        else {
                            if (record.participantType === "BANK" && status === DokuStatus.WAITING_CLIENT_SIGNATURE) {
                                return _jsx(WaitLargeIcon, { height: 16, width: 16 });
                            }
                            else {
                                if (record.participantType === "CLIENT") {
                                    if (status === DokuStatus.WAITING_CLIENT_SIGNATURE) {
                                        return _jsx(WaitLargeIcon, { height: 16, width: 16 });
                                    }
                                    else if (afterClientSignatureStatuses.includes(status)) {
                                        return _jsx(SuccessSmallIcon, { fill: "#3d7d43", height: 16, width: 16 });
                                    }
                                }
                                else {
                                    if (status === DokuStatus.WAITING_BANK_SIGNATURE) {
                                        return _jsx(WaitLargeIcon, { height: 16, width: 16 });
                                    }
                                    else if (afterBankSignatureStatuses.includes(status)) {
                                        return _jsx(SuccessSmallIcon, { fill: "#3d7d43", height: 16, width: 16 });
                                    }
                                }
                            }
                        }
                    }
                }
            },
        },
    ];
    var handeExpand = function (expanded, record) {
        setExpandedRowKeys(expanded ? [record.key] : []);
    };
    var prepareDataSource = function (dokus) {
        return dokus.map(function (doku) {
            var listItem = doku;
            listItem.key = doku.id;
            return listItem;
        });
    };
    var prepareParticipantDataSource = function (doku, participants) {
        return participants.map(function (participant) {
            var listItem = participant;
            listItem.dokuID = doku.id;
            return listItem;
        });
    };
    return (_jsx(Modal, __assign({ title: formatMessage("dde.doku.progress.modal.title"), open: props.openModal, onOk: function () { return props.setOpenModal(false); }, onClose: function () { return props.setOpenModal(false); }, cancelButtonProps: { style: { visibility: "hidden" } }, width: "100vw" }, { children: _jsx(DokuBoxModalTableStyle, { children: _jsx(Table, { showHeader: false, pagination: false, dataSource: prepareDataSource(props.dokuBox.dokus.filter(function (doku) { return doku.usedInBox; })), columns: columns, expandIconColumnIndex: 2, expandable: {
                    expandedRowKeys: expandedRowKeys,
                    expandedRowRender: function (record) { return (_jsx(SubTableStyle, { children: _jsx(Table, { pagination: false, dataSource: orderParticipant(prepareParticipantDataSource(record, record.participants)), columns: subColumns }) })); },
                    expandIcon: function (_a) {
                        var expanded = _a.expanded, onExpand = _a.onExpand, record = _a.record;
                        return expanded ? (_jsx(UpOutlined, { height: 16, width: 16, onClick: function (e) { return onExpand(record, e); } }))
                            :
                                (_jsx(DownOutlined, { height: 16, width: 16, onClick: function (e) { return onExpand(record, e); } }));
                    },
                    rowExpandable: function (record) { return true; },
                    onExpand: handeExpand,
                } }) }) })));
};
export default DokuBoxProgressModal;
