var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Progress } from "antd";
import { clientEsaw } from "./domain";
import { Button } from "@g4p/react-styleguide-library";
import { DokuStatus } from "../../admin/DokuBoxList/services/domain";
import { useState } from "react";
import DokuBoxProgressModal from "../DokuBoxProgressModal/DokuBoxProgressModal";
import { DokuPanelProgressBarTitle } from "../DokuBoxProgressModal/DokuBoxProgressModalStyle";
import { useFormatMessage } from "../../../services/formatMessageHook";
var calculateSignerNumber = function (doku) {
    return doku.participants.filter(function (participant) { return participant.participantState === "DONE"; }).length;
};
var calculateAllSignerNumber = function (doku) {
    if (doku.status === DokuStatus.WAITING_CLIENT_SIGNATURE) {
        return doku.participants.length;
    }
    else if (doku.status === DokuStatus.WAITING_BANK_SIGNATURE) {
        return doku.participants.filter(function (participant) { return participant.participantType === "BANK"
            || (participant.participantType === "CLIENT" && participant.participantState === "DONE"); }).length;
    }
    else {
        return doku.participants.filter(function (participant) {
            return (participant.participantType === "BANK" && participant.participantState === "DONE")
                || (participant.participantType === "CLIENT" && participant.participantState === "DONE");
        }).length;
    }
};
export var isEsawDokuBox = function (dokuBox) { return clientEsaw.includes(dokuBox.clientSignatureSystem) && dokuBox.bankSignatureSystem === "ESAW_LOCAL"; };
var DokuPanelBodyProgressBar = function (props) {
    var formatMessage = useFormatMessage().formatMessage;
    var _a = useState(false), openModal = _a[0], setOpenModal = _a[1];
    var signatureText = function (doku, dokuBox) {
        if (isEsawDokuBox(dokuBox))
            switch (doku.status) {
                case DokuStatus.WAITING_CLIENT_SIGNATURE:
                    return formatMessage("dde.doku.progress.client.signature.out.of.possible", {
                        signerNumber: calculateSignerNumber(doku),
                        allSignerNumber: calculateAllSignerNumber(doku)
                    });
                case DokuStatus.WAITING_BANK_SIGNATURE:
                    return formatMessage("dde.doku.progress.bank.signature.out.of.possible", {
                        signerNumber: calculateSignerNumber(doku),
                        allSignerNumber: calculateAllSignerNumber(doku)
                    });
                case DokuStatus.WAITING_ARCHIVAL:
                    return formatMessage("dde.doku.progress.all.possible.signer", {
                        signerNumber: calculateSignerNumber(doku),
                        allSignerNumber: calculateAllSignerNumber(doku)
                    });
                case DokuStatus.ARCHIVED:
                    return formatMessage("dde.doku.progress.signed.out.of", {
                        signerNumber: calculateSignerNumber(doku),
                        allSignerNumber: calculateAllSignerNumber(doku)
                    });
                default: formatMessage("dde.doku.progress.all.possible.signer", {
                    signerNumber: calculateSignerNumber(doku),
                    allSignerNumber: calculateAllSignerNumber(doku)
                });
            }
        else {
            switch (doku.status) {
                case DokuStatus.WAITING_CLIENT_SIGNATURE: return formatMessage("dde.doku.progress.client.signature.in.progress");
                case DokuStatus.WAITING_BANK_SIGNATURE: return formatMessage("dde.doku.progress.bank.signature.in.progress");
                default: "";
            }
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(DokuPanelProgressBarTitle, __assign({ "$inTheModal": props.inTheModal }, { children: [_jsx("span", __assign({ style: props.inTheModal ? { "fontSize": "15px" } : {} }, { children: signatureText(props.doku, props.dokuBox) })), !props.inTheModal && _jsx(Button, __assign({ type: "text", style: { color: "#007A91" }, onClick: function () { return setOpenModal(true); } }, { children: formatMessage("dde.doku.progress.view.all.signers") }))] })), isEsawDokuBox(props.dokuBox) && _jsx(Progress, { strokeColor: "#007A91", showInfo: false, percent: (calculateSignerNumber(props.doku) / calculateAllSignerNumber(props.doku)) * 100 }), _jsx(DokuBoxProgressModal, { setOpenModal: setOpenModal, openModal: openModal, dokuBox: props.dokuBox })] }));
};
export default DokuPanelBodyProgressBar;
