var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, DeleteIcon, Form, showErrorMessage, showSuccessMessage, Spinner } from "@g4p/react-styleguide-library";
import { MainContainerStyled } from "../../../styles/styles";
import ClientDocumentUploadBanner from "./components/ClientDocumentUploadBanner";
import { Typography, Upload } from "antd";
import { DraggerContiner, DraggerTextContainer, StickyClientDocumentFooter } from "./styles/ClientDocumentUploadStyle";
import { useState } from "react";
import ClientDocumentBox from "./components/ClientDocumentBox";
import { useFormatMessage } from "../../../services/formatMessageHook";
import { executeAxios } from "../../../services/axiosHook";
import { useNavigate } from "react-router-dom";
import { formatServerError } from "../../banker/BankerManageDokuBox/services/bankerManageDokuBoxApi";
import { useTenantContext } from "../../../services/tenantContext";
import { SegmentType, useAppContext } from "../../../services/appContext";
var checkFileValidityOnClientUploadDocuments = {
    url: "/dde/dokubox/doku/check-file-validity-on-client-upload-documents",
    method: "POST",
};
var deleteTemporaryFileApiConfig = {
    url: "/dde/dokubox/doku/delete-temporary-file",
    method: "POST",
};
var createClientDokumentsDokuboxApiConfig = {
    url: "/dde/dokubox/client/create/auto",
    method: "POST",
};
var ClientDocumentUpload = function () {
    var form = Form.useForm()[0];
    var Dragger = Upload.Dragger;
    var tenant = useTenantContext().tenant;
    var _a = useFormatMessage(), formatMessage = _a.formatMessage, formatRichTextMessage = _a.formatRichTextMessage;
    var _b = useState([]), clientDocuments = _b[0], setClientDocuments = _b[1];
    var _c = useState(formatMessage("dde.clientupload.default.custom.name")), documentName = _c[0], setDocumentName = _c[1];
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var user = useAppContext().user;
    var acceptedFileTypes = function () {
        switch (tenant) {
            case "HU":
                return ["pdf", "docx", "es3", "png", "jpg", "jpeg", "xlsx", "csv", "et3", "asice", "dosszie"];
            case "HR":
                return ["pdf", "docx", "png", "jpg", "jpeg", "xlsx", "csv", "asice", "msg"];
            case "RO":
                return ["pdf", "docx", "png", "jpg", "jpeg", "xlsx", "csv", "asice", "msg"];
            case "BG":
                return ["pdf", "docx", "doc", "png", "jpg", "jpeg", "xlsx", "csv", "asice", "msg"];
            default:
                return ["pdf", "docx", "png", "jpg", "jpeg", "xlsx", "csv", "asice"];
        }
    };
    var maxFileNumber = 50;
    var navigate = useNavigate();
    var removeClientDocument = function (uid) {
        setClientDocuments(clientDocuments.filter(function (clientDocument) { return clientDocument.fileDTO.uid !== uid; }));
        deleteTemporaryFile(clientDocuments.filter(function (clientDocument) { return clientDocument.fileDTO.uid === uid; }).map(function (document) { return document.temporaryStorageId; }));
    };
    var uploadDisableStyle = {
        "opacity": clientDocuments.length > maxFileNumber ? 0.2 : 1,
        "cursor": clientDocuments.length > maxFileNumber ? "not-allowed" : "default",
    };
    var isForbiddenExtension = function (file) {
        var fileExtArr = file.name.split(".");
        var fileExt = fileExtArr[fileExtArr.length - 1];
        return !acceptedFileTypes().includes(String(fileExt).toLowerCase());
    };
    var updateFileName = function (newFileName, uid) {
        setClientDocuments(clientDocuments.map(function (clientDocument) {
            if (clientDocument.fileDTO.uid === uid) {
                clientDocument.customName = newFileName;
            }
            return clientDocument;
        }));
    };
    var deleteTemporaryFile = function (temporaryIds) {
        if (temporaryIds[0] !== "") {
            var apiConfig = {
                data: temporaryIds,
            };
            executeAxios({
                params: __assign(__assign({}, deleteTemporaryFileApiConfig), apiConfig),
                onFail: function (resp) {
                    var _a;
                    showErrorMessage({
                        title: formatMessage(((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) || "dde.error.generalError"),
                    });
                },
            });
        }
    };
    var updateDescription = function (newDescription, uid) {
        setClientDocuments(clientDocuments.map(function (clientDocument) {
            if (clientDocument.fileDTO.uid === uid) {
                clientDocument.description = newDescription;
            }
            return clientDocument;
        }));
    };
    var validateDocuments = function () {
        if (clientDocuments.length === 0) {
            showErrorMessage({
                title: formatMessage("dde.error.errorTitle"),
                message: formatMessage("dde.clientupload.error.zerofilenumber"),
            });
        }
        if (clientDocuments.length > maxFileNumber) {
            showErrorMessage({
                title: formatMessage("dde.error.errorTitle"),
                message: formatMessage("'dde.clientupload.error.maxfilenumber", { maxFileNumber: maxFileNumber }),
            });
        }
        if (clientDocuments.every(function (clientDocument) {
            var _a, _b;
            return clientDocument.isCorrect
                && clientDocument.customName.length < 100 && (((_a = clientDocument.description) === null || _a === void 0 ? void 0 : _a.length) === undefined || ((_b = clientDocument.description) === null || _b === void 0 ? void 0 : _b.length) < 100);
        })
            && clientDocuments.length !== 0 && clientDocuments.length < maxFileNumber) {
            var uploadDocuments = {
                customName: documentName,
                dokuboxDefinitionId: user.segment === SegmentType.MB ? -9192 : -9191,
                clientDocuments: clientDocuments.map(function (_a) {
                    var temporaryStorageId = _a.temporaryStorageId, customName = _a.customName, description = _a.description, size = _a.size, name = _a.name;
                    return ({
                        temporaryStorageId: temporaryStorageId,
                        customName: customName,
                        description: description,
                        size: size,
                        name: name
                    });
                }),
            };
            uploadDocuments.clientDocuments.forEach(function (document) {
                if (document.name === document.customName) {
                    document.customName = "";
                }
            });
            var apiConfig = {
                data: uploadDocuments,
            };
            executeAxios({
                params: __assign(__assign({}, createClientDokumentsDokuboxApiConfig), apiConfig),
                onLoading: setIsLoading,
                onFail: function (resp) {
                    var _a;
                    showErrorMessage({
                        title: formatMessage(((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) || "dde.error.generalError"),
                    });
                },
                onSuccess: function () {
                    navigate("/app/dokuboxlist/");
                    showSuccessMessage({ title: formatMessage("dde.clientupload.success.upload.title"), message: formatMessage("dde.clientupload.message.upload.title") });
                },
            });
        }
    };
    var getFile = function (file) {
        var document = {
            temporaryStorageId: "",
            fileDTO: file,
            customName: file.name,
            size: file.size,
            name: file.name
        };
        if (isForbiddenExtension(file)) {
            document.errorMessage = formatMessage("dde.error.fileupload.forbidden.extenstion");
        }
        var isLt10M = file.size / 1024 / 1024 > 10;
        if (isLt10M) {
            document.errorMessage = formatMessage("dde.error.file.upload.maxsize", { maxSize: 10 });
        }
        var isEmpty = file.size === 0;
        if (isEmpty) {
            document.errorMessage = formatMessage("dde.error.file.upload.empty");
        }
        var formData = new FormData();
        formData.append("file", file);
        var apiConfig = {
            data: formData,
        };
        executeAxios({
            params: __assign(__assign({}, checkFileValidityOnClientUploadDocuments), apiConfig),
            onSuccess: function (response) {
                document.isCorrect = true;
                document.errorMessage = "";
                document.temporaryStorageId = response;
                setClientDocuments(function (prevState) { return __spreadArray(__spreadArray([], prevState, true), [document], false); });
            },
            onLoading: setIsLoading,
            onFail: function (resp) {
                document.isCorrect = false;
                document.errorMessage = formatServerError(resp, formatMessage);
                setClientDocuments(function (prevState) { return __spreadArray(__spreadArray([], prevState, true), [document], false); });
            },
        });
    };
    var uploadProps = {
        multiple: true,
        accept: acceptedFileTypes().map(function (type) { return "." + type; }).join(", "),
        beforeUpload: getFile,
        itemRender: function (originNode, file) {
            return (_jsx(_Fragment, {}));
        },
    };
    return (_jsxs(Form, __assign({ form: form }, { children: [_jsx(ClientDocumentUploadBanner, { documentCount: clientDocuments.length, documentName: documentName, setDocumentName: setDocumentName, deleteTemporaryFiles: deleteTemporaryFile, temporaryIds: clientDocuments.map(function (document) { return document.temporaryStorageId; }) }), _jsxs(MainContainerStyled, { children: [_jsxs(DraggerContiner, { children: [_jsxs(Dragger, __assign({ disabled: clientDocuments.length > maxFileNumber }, uploadProps, { style: { width: "100%", marginTop: "20px", height: "200px" } }, { children: [_jsx(Typography.Text, __assign({ style: uploadDisableStyle }, { children: _jsx("b", { children: formatMessage("dde.clientupload.upload.text.drag") }) })), _jsx("br", {}), _jsx(Typography.Text, __assign({ style: uploadDisableStyle }, { children: formatMessage("dde.clientupload.upload.text.or") })), _jsx("br", {}), _jsx(Button, __assign({ type: "primary", style: uploadDisableStyle }, { children: formatMessage("dde.clientupload.upload.text.select") }))] })), _jsxs(DraggerTextContainer, { children: [_jsx(Typography.Text, { children: formatMessage("dde.clientupload.upload.left.text") }), _jsx(Typography.Text, { children: formatRichTextMessage("dde.clientupload.upload.right.text", {
                                            maxFileNumber: maxFileNumber,
                                            acceptedFileTypes: acceptedFileTypes().map(function (type) { return "." + type; }).join(", "),
                                            maxFileSize: 10,
                                        }) })] })] }), clientDocuments.map(function (_a, index) {
                        var fileDTO = _a.fileDTO, customName = _a.customName, description = _a.description, errorMessage = _a.errorMessage, isCorrect = _a.isCorrect;
                        return _jsx(ClientDocumentBox, { index: index + 1, uid: fileDTO.uid, fileName: fileDTO.name, customName: customName, size: fileDTO.size, description: description, remove: removeClientDocument, errorMessage: errorMessage, isCorrect: isCorrect, updateFileName: updateFileName, updateDescription: updateDescription }, fileDTO.uid);
                    }), isLoading && _jsx(Spinner, {}), _jsxs(StickyClientDocumentFooter, __assign({ "$fileSize": clientDocuments.length }, { children: [_jsx("div", __assign({ style: { display: "flex", alignItems: "center" } }, { children: clientDocuments.length > 0 && (_jsx(Typography.Text, { children: formatRichTextMessage("dde.clientupload.document.number", { clientDocumentsNumber: clientDocuments.length }) })) })), _jsxs("div", __assign({ style: { display: "flex" } }, { children: [clientDocuments.length > 0 && (_jsx(Button, __assign({ type: "text", icon: _jsx(DeleteIcon, { fill: "#007A91" }), onClick: function () {
                                            deleteTemporaryFile(clientDocuments.map(function (document) { return document.temporaryStorageId; }));
                                            setClientDocuments([]);
                                        }, style: { marginRight: "10px", color: "#007A91" } }, { children: formatMessage("dde.clientupload.clear.list") }))), _jsx(Button, __assign({ type: "primary", onClick: validateDocuments }, { children: formatMessage("dde.doku.action.SendToBank") }))] }))] }))] })] })));
};
export default ClientDocumentUpload;
