var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { DokuBoxStatus } from "./services/domain";
import styled from "styled-components";
export var getStatusColor = function (status) {
    switch (status) {
        case DokuBoxStatus.IN_PROGRESS:
            return "#EC6608";
        case DokuBoxStatus.FINALIZED:
            return "#3D7D43";
        case DokuBoxStatus.CANCELLED:
            return "#CF3524";
        default:
            return "#666666";
    }
};
export var PaginationFooter = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n"], ["\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n"])));
var templateObject_1;
