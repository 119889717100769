var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export function CustomExpandIcon(_a) {
    var expanded = _a.expanded, onClick = _a.onClick;
    var rotateAndApply = function (event) {
        event.stopPropagation();
        onClick(event);
    };
    return (_jsx("button", __assign({ style: {
            cursor: "pointer", border: "none", display: "flex",
            alignItems: "center", justifyContent: "center", backgroundColor: "transparent"
        }, onClick: rotateAndApply }, { children: _jsxs("svg", __assign({ width: "20", height: "19", viewBox: "0 0 20 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { width: "18", height: "18", transform: "translate(1 0.5)", fill: "white" }), _jsx("path", { d: "M10 0.5C5.02919 0.5 1 4.52975 1 9.5C1 14.4703 5.02919 18.5 10 18.5C14.9703 18.5 19 14.4703 19 9.5C19 4.52975 14.9703 0.5 10 0.5", stroke: "#909090" }), _jsx("path", { style: {
                        transform: "rotate(".concat(expanded ? 90 : 0, "deg)"),
                        transformOrigin: '50% 50%',
                        transition: 'transform 0.3s ease',
                    }, fillRule: "evenodd", clipRule: "evenodd", d: "M9.15625 14.5625H10.8438V4.4375H9.15625V14.5625Z", fill: "#262626" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.9375 10.3438H15.0625V8.65625H4.9375V10.3438Z", fill: "#262626" })] })) })));
}
