var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var DokuPanelProgressBarTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    ", "\n    \n    padding-top: 10px;\n   \n"], ["\n    display: flex;\n    align-items: center;\n    ", "\n    \n    padding-top: 10px;\n   \n"])), function (props) { return props.$inTheModal ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        justify-content: center;\n        padding-bottom: 0px;\n      "], ["\n        justify-content: center;\n        padding-bottom: 0px;\n      "]))) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      justify-content: space-between;\n      padding-bottom: 5px;\n     "], ["\n      justify-content: space-between;\n      padding-bottom: 5px;\n     "]))); });
export var ColumnContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n"], ["\n    display: flex;\n    align-items: center;\n"])));
export var DokuBoxModalTableStyle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {\n        margin: 0;\n    }\n"], ["\n    .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {\n        margin: 0;\n    }\n"])));
export var SubTableStyle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    td.ant-table-cell {\n\t\t\t\tfont-size: 14px;\n\t\t}\n"], ["\n    td.ant-table-cell {\n\t\t\t\tfont-size: 14px;\n\t\t}\n"])));
export var TooltipText = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    font-size: 15px;\n    padding: 0;\n    margin: 0;\n    &:first-child{\n      font-weight: bold;\n    }\n"], ["\n    font-size: 15px;\n    padding: 0;\n    margin: 0;\n    &:first-child{\n      font-weight: bold;\n    }\n"])));
export var ToolTipBox = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    padding: 5px;\n"], ["\n    padding: 5px;\n"])));
export var LablePending = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #EC6608;\n    font-size: 12px;\n    font-weight: bold;\n    line-height: 16px;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    margin-left: 5px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"], ["\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #EC6608;\n    font-size: 12px;\n    font-weight: bold;\n    line-height: 16px;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    margin-left: 5px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"])));
export var LabelNotRequired = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #666666;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    font-size: 12px;\n    margin-left: 5px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"], ["\n\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #666666;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    font-size: 12px;\n    margin-left: 5px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"])));
export var LabelFinalized = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #2e844d;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    font-size: 12px;\n    margin-left: 5px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"], ["\n\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #2e844d;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    font-size: 12px;\n    margin-left: 5px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"])));
export var LabelCanceled = styled.span(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #d73928;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    font-size: 12px;\n    margin-left: 5px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"], ["\n\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #d73928;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    font-size: 12px;\n    margin-left: 5px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
