import { useState } from "react";
var usePagination = function (initialSize, initialPage) {
    if (initialSize === void 0) { initialSize = 10; }
    if (initialPage === void 0) { initialPage = 1; }
    var _a = useState(initialSize), pageSize = _a[0], setPageSize = _a[1];
    var _b = useState(initialPage), currentPage = _b[0], setCurrentPage = _b[1];
    var paginate = function (data) { return data.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize); };
    return { paginate: paginate, currentPage: currentPage, setCurrentPage: setCurrentPage, pageSize: pageSize, setPageSize: setPageSize };
};
export default usePagination;
