var firstOrder = {
    "CLIENT": 1,
    "BANK": 2,
};
var secondOrder = {
    "BANK_1STLEVELSIGNATORY": 1,
    "BANK_2NDLEVELSIGNATORY": 2,
    "BANK_NONLEGALREPSIGNATORY": 3,
    "BANK_REVIEWER": 4,
    "BANK_RM": 5,
    "BANK_VALIDATOR": 6,
    "BANK_VIEWER": 7,
    "CLIENT_EMPLOYEESIGNATORY": 8,
    "CLIENT_LEGALREPRESENTATIVE": 9,
    "CLIENT_EMPLOYEE": 10,
    "CLIENT_REVIEWER": 11,
    "CLIENT_VIEWER": 12
};
export var orderParticipant = function (participants) {
    return participants.sort(function (a, b) {
        if (firstOrder[a.participantType] === firstOrder[b.participantType]) {
            return secondOrder[a.participantRole] - secondOrder[b.participantRole];
        }
        return firstOrder[a.participantType] - firstOrder[b.participantType];
    });
};
