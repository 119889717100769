var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from "@g4p/react-styleguide-library";
import { useFormatMessage } from "../../../../services/formatMessageHook";
var paginationOptions = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 }
];
var PageSizeOptionSelector = function (_a) {
    var value = _a.value, onChange = _a.onChange;
    var formatMessage = useFormatMessage().formatMessage;
    return (_jsx("div", __assign({ style: { width: 344 } }, { children: _jsx(Select, { options: paginationOptions, onChange: onChange, value: value, formatOptionLabel: function (data) { return (_jsxs("div", __assign({ style: { width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", paddingInline: 0 } }, { children: [_jsx("span", __assign({ style: { display: "flex", flex: 1 } }, { children: formatMessage("dde.pagination.options.prefix") })), _jsx("span", __assign({ style: { fontWeight: "bold", fontSize: 16, display: "flex", alignItems: "center" } }, { children: data.label }))] }))); } }) })));
};
export default PageSizeOptionSelector;
