var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var DraggerContiner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    .ant-upload.ant-upload-drag{\n        border: 2px dashed #d9d9d9;\n\t\t}\n"], ["\n    .ant-upload.ant-upload-drag{\n        border: 2px dashed #d9d9d9;\n\t\t}\n"])));
export var DraggerTextContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n\t\tjustify-content: space-between;\n\t\talign-items: center;\n"], ["\n    display: flex;\n\t\tjustify-content: space-between;\n\t\talign-items: center;\n"])));
export var StickyClientDocumentFooter = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    position: sticky;\n    bottom: 0px;\n    display: flex;\n    justify-content: space-between;\n    background-color: #ffffff;\n    padding: 5px;\n    padding-top: 10px;\n    padding-bottom: 20px;\n    margin-bottom: -40px;\n    gap: 20px;\n\t\t", "\n"], ["\n    position: sticky;\n    bottom: 0px;\n    display: flex;\n    justify-content: space-between;\n    background-color: #ffffff;\n    padding: 5px;\n    padding-top: 10px;\n    padding-bottom: 20px;\n    margin-bottom: -40px;\n    gap: 20px;\n\t\t", "\n"])), function (props) { return props.$fileSize === 0 ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["margin-top: 170px"], ["margin-top: 170px"]))) :
    props.$fileSize === 1 ? css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["margin-top: 80px"], ["margin-top: 80px"]))) : css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["margin-top: 0"], ["margin-top: 0"]))); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
