var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClientDocumentUploadBannerContainer } from "../styles/ClientDocumentUploadBannerStyle";
import { MainContainerStyled } from "../../../../styles/styles";
import { BackIcon, Button, CloseIcon, EditIcon, Input, Modal } from "@g4p/react-styleguide-library";
import { useState } from "react";
import { useFormatMessage } from "../../../../services/formatMessageHook";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
var ClientDocumentUploadBanner = function (_a) {
    var documentCount = _a.documentCount, documentName = _a.documentName, setDocumentName = _a.setDocumentName, temporaryIds = _a.temporaryIds, deleteTemporaryFiles = _a.deleteTemporaryFiles;
    var formatMessage = useFormatMessage().formatMessage;
    var navigate = useNavigate();
    var _b = useState(""), temporaryDocumentName = _b[0], setTemporaryDocumentName = _b[1];
    var _c = useState(false), isEditable = _c[0], setIsEditable = _c[1];
    var _d = useState(false), modalVisible = _d[0], setModalVisible = _d[1];
    var editDocumentName = function () {
        setDocumentName(temporaryDocumentName);
        setIsEditable(false);
    };
    return (_jsxs(ClientDocumentUploadBannerContainer, { children: [_jsx(MainContainerStyled, { children: _jsxs("div", __assign({ style: {
                        justifyContent: "flex-start",
                        display: "flex",
                        flexDirection: "column",
                    } }, { children: [_jsx(Button, __assign({ type: "text", style: {
                                fontSize: 14,
                                fontWeight: "bold",
                                color: "#007A91",
                                width: "fit-content",
                                marginRight: "auto",
                                marginTop: "10px",
                            }, icon: _jsx(BackIcon, { style: { fill: "#007A91" } }), onClick: function () {
                                if (documentCount === 0) {
                                    navigate("/app/dokuboxlist/");
                                }
                                else {
                                    setModalVisible(true);
                                }
                            } }, { children: formatMessage("dokubox.create.button.Back") })), isEditable ? (_jsxs("div", __assign({ style: { display: "flex", alignItems: "center" } }, { children: [_jsx(CloseIcon, { style: { marginRight: "5px" }, height: 18, width: 18, onClick: function () { return setIsEditable(false); } }), _jsx(Input, { defaultValue: documentName, onChange: function (e) { return setTemporaryDocumentName(e.target.value); } }), _jsx(Button, __assign({ style: { marginLeft: "5px" }, type: "primary", onClick: editDocumentName }, { children: formatMessage("apply") }))] }))) : (_jsxs("div", __assign({ style: { display: "flex", alignItems: "center" } }, { children: [_jsx(Typography.Title, __assign({ level: 2 }, { children: documentName })), _jsx(EditIcon, { style: { marginLeft: "5px", marginBottom: "15px" }, height: 18, width: 18, onClick: function () { return setIsEditable(true); } })] }))), _jsx(Typography.Text, { children: formatMessage("dde.clientupload.header.text") })] })) }), _jsx(Modal, __assign({ title: formatMessage("dde.clientupload.back.modal.title"), open: modalVisible, onCancel: function () { return setModalVisible(false); }, onOk: function () {
                    deleteTemporaryFiles(temporaryIds);
                    navigate("/app/dokuboxlist/");
                }, cancelText: formatMessage("Cancel"), okText: formatMessage("continue"), closeIcon: _jsx(CloseIcon, { height: 0, width: 0 }) }, { children: _jsx(Typography.Text, { children: formatMessage("dde.clientupload.back.modal.text") }) }))] }));
};
export default ClientDocumentUploadBanner;
