var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClientDocumentBoxContainer, ClientDocumentBoxInput, ClientDocumentBoxTitle, } from "../styles/ClientDocumentBoxStyle";
import { Typography } from "antd";
import { CloseIcon, DeleteIcon, DocumentsIcon, Input, SuccessSmallIcon, } from "@g4p/react-styleguide-library";
import { useFormatMessage } from "../../../../services/formatMessageHook";
var ClientDocumentBox = function (props) {
    var _a;
    var formatMessage = useFormatMessage().formatMessage;
    var maxFieldSize = 100;
    var fileSizeFormatter = function (size) {
        if (size === undefined)
            return 0;
        return Math.round(size / 1000) + "KB";
    };
    var documentMessage = function (isCorrect, errorMessage) {
        if (isCorrect === undefined) {
            return _jsx("p", {});
        }
        else if (isCorrect) {
            return _jsxs("div", __assign({ style: { display: "flex", alignItems: "center" } }, { children: [_jsx(SuccessSmallIcon, { width: 16, height: 16, fill: "#3d7d43", style: { marginRight: "5px" } }), " ", formatMessage("uploaded").toUpperCase()] }));
        }
        else {
            return _jsxs("div", __assign({ style: { display: "flex", alignItems: "center" } }, { children: [_jsx(CloseIcon, { width: 16, height: 16, fill: "#cf3524", style: { marginRight: "5px" } }), errorMessage] }));
        }
    };
    var maxSizeExpression = function (inputValue) { return inputValue !== undefined && inputValue.length > maxFieldSize; };
    var maxSizeErrorMessage = function (inputValue, isFileName) {
        if (maxSizeExpression(inputValue)) {
            return isFileName ? _jsxs("p", __assign({ style: { color: "#cf3524" } }, { children: [formatMessage("dde.clientupload.filename.size.error", { maxLength: maxFieldSize }), " "] })) :
                _jsxs("p", __assign({ style: { color: "#cf3524" } }, { children: [formatMessage("dde.clientupload.description.size.error", { maxLength: maxFieldSize }), " "] }));
        }
    };
    return (_jsxs(ClientDocumentBoxContainer, { children: [_jsxs(ClientDocumentBoxTitle, { children: [_jsx("div", __assign({ style: { display: "flex", alignItems: "center" } }, { children: _jsxs(Typography.Text, __assign({ style: { alignItems: "center" } }, { children: [_jsx(DocumentsIcon, { height: 16, width: 16 }), _jsxs("b", { children: [" ", props.index, ". ", props.fileName] }), " | ", _jsx("span", __assign({ style: { color: props.errorMessage === formatMessage("dde.error.file.upload.maxsize", { maxSize: 10 }) ? "#cf3524" : "black" } }, { children: fileSizeFormatter(props.size) }))] })) })), _jsxs("div", __assign({ style: { display: "flex", alignItems: "center" } }, { children: [documentMessage(props.isCorrect, props.errorMessage), _jsx(DeleteIcon, { style: { marginLeft: "10px" }, fill: "#007A91", height: 16, width: 16, onClick: function () {
                                    props.remove(props.uid);
                                } })] }))] }), _jsxs(ClientDocumentBoxInput, { children: [_jsxs("div", __assign({ style: { flex: 0.45, marginBottom: maxSizeExpression(props.description) ? "35px" : "0px" } }, { children: [_jsx("label", { children: "File Name" }), _jsx(Input, { defaultValue: (_a = props.customName) === null || _a === void 0 ? void 0 : _a.split(".")[0], disabled: props.isCorrect === false, onChange: function (e) { return props.updateFileName(e.target.value, props.uid); } }), maxSizeErrorMessage(props.customName, true)] })), _jsxs("div", __assign({ style: { flex: 0.45, marginBottom: maxSizeExpression(props.fileName) ? "35px" : "0px" } }, { children: [_jsx("label", { children: "Description for the Bank" }), _jsx(Input, { defaultValue: "Please insert", disabled: props.isCorrect === false, onChange: function (e) { return props.updateDescription(e.target.value, props.uid); } }), maxSizeErrorMessage(props.description, false)] }))] })] }));
};
export default ClientDocumentBox;
