var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Tooltip } from "@g4p/react-styleguide-library";
import Typography from "antd/lib/typography";
import { PageTitleStyled } from "./styles";
export var PageTitle = function (props) {
    var _a, _b, _c, _d;
    return (_jsxs(PageTitleStyled, { children: [_jsx(Typography.Title, __assign({ className: "title", level: 3, style: { margin: 0 } }, { children: props.title })), props.cta && (_jsx("div", __assign({ className: "cta-wrap" }, { children: _jsx(Tooltip, __assign({ title: ((_a = props.cta) === null || _a === void 0 ? void 0 : _a.tooltip) || "" }, { children: _jsxs(Button, __assign({ type: "primary", onClick: props.cta.onClick, disabled: ((_b = props.cta) === null || _b === void 0 ? void 0 : _b.disabled) === true }, { children: [(_d = (_c = props.cta) === null || _c === void 0 ? void 0 : _c.icon) !== null && _d !== void 0 ? _d : null, props.cta.label] })) })) })))] }));
};
