var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UpIcon } from "@cbp/fe-commons";
import { Collapse } from "antd";
import { useState } from "react";
var CustomCollapse = function (_a) {
    var color = _a.color, _b = _a.openedColor, openedColor = _b === void 0 ? color : _b, title = _a.title, _c = _a.openedTitle, openedTitle = _c === void 0 ? title : _c, _d = _a.isOpenedByDefault, isOpenedByDefault = _d === void 0 ? false : _d, children = _a.children;
    var Panel = Collapse.Panel;
    var _e = useState(isOpenedByDefault), isOpen = _e[0], setIsOpen = _e[1];
    return (_jsx(Collapse, __assign({ defaultActiveKey: isOpenedByDefault ? ['1'] : [], ghost: true, onChange: function (activeKeys) {
            setIsOpen(activeKeys.length > 0);
        } }, { children: _jsx(Panel, __assign({ style: {
                border: "none",
                padding: 0,
                paddingBottom: isOpen ? 20 : 0,
            }, showArrow: false, header: _jsxs("div", __assign({ style: { display: "flex", flexDirection: "row", gap: 4, alignItems: "center" } }, { children: [_jsx("div", { children: _jsx("p", __assign({ style: { fontWeight: "bold", color: isOpen ? openedColor : color, margin: 0, width: "fit-content" } }, { children: isOpen ? openedTitle : title })) }), _jsx("div", __assign({ style: { display: "flex", alignItems: "center" } }, { children: _jsx(UpIcon, { width: 14, height: 14, fill: isOpen ? openedColor : color, style: {
                                transition: "transform 0.24s ease",
                                transform: isOpen ? "none" : "rotate(180deg)"
                            } }) }))] })) }, { children: children }), "1") })));
};
export default CustomCollapse;
